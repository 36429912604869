import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/guard/auth.guard";
import { LandingribbionComponent } from "../pages/master/landingribbion/landingribbion.component";
import { BaseComponent } from "./base/base.component";

const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      // {
      //   path: 'dashboard',
      //   loadChildren: () => import('./../../views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      // },
      {
        path: 'dashboard',
        loadChildren: () => import('./../../views/pages/trenddashboard/trenddashboard.module').then(m => m.TrenddashboardModule)
      },
      {
        path: '', redirectTo: 'dashboard', pathMatch: 'full'
      },
      
      {
        path: 'profile/:id/:actionInfo',
        loadChildren: () => import('./../../views/pages/profile/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'monthlyreport',
        loadChildren: () => import('./../../views/pages/reports/monthlyreport/monthlyreport.module').then(m => m.MonthlyReportModule)
      },
      {
        path: 'childcount',
        loadChildren: () => import('./../../views/pages/reports/childcountreport/childcountreport.module').then(m => m.ChildcountReportModule)
      },
      
      {
        path: 'phasetworeport',
        loadChildren: () => import('./../../views/pages/reports/phasetworeport/phasetworeport.module').then(m => m.PhasetworeportModule)
      },
      {
        path: 'genderwisechildcount',
        loadChildren: () => import('./../../views/pages/reports/genderwisechildcountreport/genderwisechildcountreport.module').then(m => m.GenderWiseChildcountReportModule)
      },
      {
        path: 'children',
        loadChildren: () => import('./../../views/pages/reports/childrenreport/childrenreport.module').then(m => m.ChildrenReportModule)
      },
      
     
      {
        path: 'jobs',
        loadChildren: () => import('./../../views/pages/master/district/district.module').then(m => m.DistrictModule)
      },
      {
        path: 'teamhead',
        loadChildren: () => import('./../../views/pages/master/projects/project.module').then(m => m.ProjectModule)
      },
      {
        path: 'projects',
        loadChildren: () => import('./../../views/pages/master/projectsunderteam/projectunderteam.module').then(m => m.ProjectUnderTeamModule)
      },
      {
        path: 'applications/:filterId',
        loadChildren: () => import('./../../views/pages/master/applications/application.module').then(m => m.ApplicationModule)
      },
      
      {
        path: 'rutf',
        loadChildren: () => import('./../../views/pages/reports/rutf/rutf.module').then(m => m.RutfModule)
      },
      
    ],
  },
  {
    path: 'ribbion',
    component: LandingribbionComponent,
    data: {
      type: 404,
      title: 'Page Not Found',
      desc: 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
