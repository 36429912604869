import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserSessionService } from './usersession.service';
import { DataService } from './data.service';
import { UserSession } from '../models/usersession';

import * as jwtDecode from 'jwt-decode';
import * as momenttz from 'moment-timezone';

import 'rxjs/add/operator/map';
import * as CryptoJS from 'crypto-js';

declare var require: any;
const timezone = require('src/assets/json/timezones.json');
import * as forge from 'node-forge';

@Injectable()
export class AuthenticationService {

  private baseUrl = environment.apiBaseUrl;
  timeZones: any[];
  pageAccess = true;
  sessionData = new UserSession();
  tokenFromUI: string = "1e2f3g4c5h7a8x9q";
  tokenFromIV: any;
  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAruzidSHRz8Pk6wy/aw3z
  U1lp+pB6BGU1LxsWGJMsJ2dRCks5+G3MBGttwOHwIMd42+PTy4tIwxBg3yI7yf2C
  mOKz+7UaG/pVQII4HfRmMxN7K5W9lf6+GSnmCdxDBtWc/4/AtRNrwHkDHwwK6bPn
  mysKZ9ymfxAT3MxKE9HpOUPaZzqqGEw7LbGW9nIWpAiBcbRTgvGRDsOuq4XVVDka
  v8H06TRgFrUtJ1HffIdW4XDJ/hUbAtzD3sLwomH4o0cLv/gnntf6HZNs51axB5Ep
  qIWXPSyHHd6Wi6Suij9/PDmrhTfOQSrrLrGxtOvchGO5H6IxGJJEhPYbozlGvJ90
  iQIDAQAB
  -----END PUBLIC KEY-----`;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private sessionService: UserSessionService) {
    this.getTimeZones();
  }

  login(username: string, password: string, isLogin: boolean) {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
      });

    const timeZone = this.getBrowserTimeZone();
    //this.tokenFromIV = (Math.random() + ' ').substring(2, 10) + (Math.random() + ' ').substring(2, 10);
    // if(password){
    //   let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    //   let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromIV);
    //   let encrypted = CryptoJS.AES.encrypt(
    //     JSON.stringify(password), _key, {
    //     keySize: 16,
    //     iv: _iv,
    //     mode: CryptoJS.mode.ECB,
    //     padding: CryptoJS.pad.Pkcs7
    //   });
    //   var encryptKeyIv = this.tokenFromIV + encrypted.toString();
    // }
    // const pwd = btoa(encryptKeyIv);
    // const data = { username, password: password, };
    var rsa = forge.pki.publicKeyFromPem(this.publicKey);
    var encryptedPassword = window.btoa(rsa.encrypt(password));
    //encryptedPassword = 'C0hXFCz1XWgje3GDEDEE2r3b8WJMBL0QCgJxqz3OdgxnRrOeYGE1oiQwQ6tI7L7k8qBGACypLxhLGR2n66KXYBNwGHIY4V4TCYKKKNzcL8WO9cSbRpsAyGBfFI9/rOg6XxK9uwGwwMC72wzygDxqqr4uK9jh0nqfhMIt/2wupLkv/xjIdI6iIJacPaObqNbSW8jvA2/HrAH028e3kGoT2fsbpBBRDUTFsPurLTJvklu04g+Bf9/8uAQZLMrQZLpayYZMf9XdHLLnvC7TTOqRUPucqMZwafzfV8ML2JcjGo/anZEMj7tKbtY9T6Y1em2ofPIBmc52EVXdlP4DGCyKDA=='
    const data = { username: username, password: encryptedPassword };
    // const data = { username, password: password, appType: 3, ucode: timeZone,token:'token', isForceLogout: isLogin };
    return this.http.post<any>(this.baseUrl + '/api/token', data, { headers })
      .map(user => {
        debugger;
        if (user && user.access_token) {
          debugger
          this.clearCachedMenu();
          const decodedToken = jwtDecode(user.access_token);
          console.log("decodedToken", decodedToken);
          this.sessionData.email = decodedToken['user.email'];
          this.sessionData.mobileNumber = decodedToken['user.mobilenumber'];
          this.sessionData.authToken = user.access_token;
          this.sessionData.userId = decodedToken['user.id'];
          this.sessionData.roleId = decodedToken['user.roleid'];
          this.sessionData.roleName = decodedToken['user.rolename'];
          this.sessionData.userFullName = decodedToken['user.fullname'];
          this.sessionData.isDynamicPassword = decodedToken.referrence1 === 'True';
          this.sessionData.districtId = decodedToken['user.districtId'];
          this.sessionData.filterblockId = decodedToken['user.blockId'];
          this.sessionData.languageType = parseInt(decodedToken['user.languagetype']);
          this.sessionService.create(this.sessionData);
        }
        return user;
      });
  }

  isAuthenticated() {
    return !!this.sessionService.userId() && !!this.sessionService.authToken();
  }

  isPageAccessAvailable(pageUrl, pageTitle) {

    const rolePages = this.sessionService.getLocalStorageWithKey('role') ? this.sessionService.getLocalStorageWithKey('role') : '[]';
    const pages = JSON.parse(rolePages);
    const paths = ['/unauthorized', '/dashboard'];
    if (pages && pages.length > 0) {
      pages.forEach(field => {
        if (field.path) {
          paths.push(field.path);
          paths.push(field.title);
        }
        if (field.submenu && field.submenu.length > 0) {
          field.submenu.forEach(field1 => {
            if (field1.path) {
              paths.push(field1.path);
              paths.push(field1.title);
              if (field1.path === '/beneficiaries/1' || field1.path === '/beneficiaries/2') {
                paths.push('beneficiaries');
              }
            }
          });
        }
      });
      this.pageAccess = (paths.indexOf(pageUrl) >= 0) || (paths.indexOf(pageTitle) >= 0) ? true : false;
    }
    return this.pageAccess;
  }

  hasRequiredPermission(permission) {
    for (let i = 0; i < permission.length; i++) {
      if (permission[i] === this.sessionService.roleId()) {
        return true;
      }
    }
    return false;
  }

  getTimeZones() {
    this.timeZones = timezone.timeZone;
  }

  getBrowserTimeZone(): string {
    const zoneName = momenttz.tz.guess();
    const temptimezone = momenttz.tz(zoneName).zoneAbbr();
    const filterZone = this.timeZones.find(i => i.abbr === temptimezone);
    if (filterZone) {
      return filterZone.value;
    }
    return '';
  }

  clearCachedMenu() {
    this.dataService.clearCache();
  }

  clearSession() {
    this.sessionService.destroy();
    this.clearCachedMenu();
  }

  logOut() {
    this.clearCachedMenu();
    this.sessionService.destroy();
  }
}
