export  enum RoleType {
    Root = 1,
    AnganwadiWorker = 2,
    Agency = 3,
    DistrictCollector = 4,
    DHO = 5,
    DPO = 6,
    BHO = 7,
    BIO = 8,
    HOHLTH = 9,
    Supervisor = 10
}