import { Injectable } from '@angular/core';
import { DataService } from './data.service';


@Injectable()
export class UtilityService {

    utilityRoute = '/api/lookup/';

    constructor(
        private dataService: DataService) { }

    getVehicleLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'vehicle', refresh);
    }


    getUserLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'user', refresh);
    }

    getLicenceTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'licensetype', refresh);
    }

    getLanguageTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'languagetype', refresh);
    }

    getFuelLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'fueltype', refresh);
    }

    getDeviceTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'devicetype', refresh);
    }
    // getDeviceLookup(data:any, refresh: Boolean) {
    //     return this.dataService.getData(this.utilityRoute + 'device/'+data.subDivisionId + '/' + data.deviceId, refresh);
    // }

    getDeviceLookup(data:any, refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'device/'+data.vehicleId, refresh);
    }
   
    getProtocolTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'protocoltype', refresh);
    }

    getTyreTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'tyretype', refresh);
    }

    getTyreMakeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'tyremake', refresh);
    }

    getAxlePositionTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'axlepositiontype', refresh);
    }

    getOwnerTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'ownertype', refresh);
    }

    getAttachmentTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'attachmenttype', refresh);
    }

    getEventStatusTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'eventstatustype', refresh);
    }

    getUOMTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'uomtype', refresh);
    }

    getPriorityTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'prioritytype', refresh);
    }

    // getMakesTypeLookup(refresh: boolean) {

    //     return this.dataService.getData(this.utilityRoute + 'vehiclemake', refresh);
    // }

    getModelsTypeLookup(makeId, refresh: boolean) {

        return this.dataService.getData(this.utilityRoute + 'vehiclemodel/' + makeId, refresh);
    }

    getConfigModuleTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'configmoduletype', refresh);
    }

    getTripTyreLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'triptyre', refresh);
    }

    getPaymentModeType(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'paymentmethodtype', refresh);
    }

    gettransportTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'transporttype', refresh);
    }

    getLocationTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'locationtype', refresh);
    }

    getClientTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'clienttype', refresh);
    }

    getNotifyTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'notifytype', refresh);
    }

    getVehicleStatusTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'vehiclestatustype', refresh);
    }
    getTripTypeLookUp(refresh: boolean) {
        return this.dataService.getData(this.utilityRoute + 'triptype', refresh);
    }

    getTransportTypeLookup(refresh: Boolean) {
        return this.dataService.getData(this.utilityRoute + 'transporttype', refresh);
    }
    getMakesTypeLookup(id: number, refresh: boolean) {

        return this.dataService.getData(this.utilityRoute + 'vehiclemake/' + id, refresh);
    }




    getYearLookup(refresh: Boolean) {
        const years = [];
        for (let index = new Date().getFullYear(); index >= 1990 ; index--) {
            years.push({ key: index, value: index });
        }
        return years;
    }

}
