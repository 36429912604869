import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()

export class NavigationService {
  constructor(private router: Router) {
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  goToLogin() {
    this.router.navigate(['/auth/login']);
  }
  goToHome() {
    this.router.navigate(['/auth/home']);
  }

  goToUnAuthorized() {
    this.router.navigate(['/unauthorized']);
  }

  goTOMiPage() {
    this.router.navigate(['/ribbion']);
  }

  goTOMiDashboard() {
    this.router.navigate(['/minewdashboard']);
  }

  goToSessionTimedOut() {
    this.router.navigate(['/sessiontimedout']);
  }

  isOnLoginScreen(): boolean {
    return this.router.url === '/login';
  }

  goToUser(id: number, actionInfo: number) {
    this.router.navigate(['/users/' + id, actionInfo]);
  }

  goToProfile(id: number, actionInfo: number) {
    this.router.navigate(['/profile/' + id, actionInfo]);
  }

  goToMap(id: number, actionInfo: number) {
    this.router.navigate(['/dashboard/' + id, actionInfo]);
  }

  goToDistrict(id: number, actionInfo: number) {
    this.router.navigate(['/jobs/' + id, actionInfo]);
  }

  goToProjects(id: number, actionInfo: number) {
    this.router.navigate(['/projects/' + id, actionInfo]);
  }

  goToTeam(id: number, actionInfo: number) {
    this.router.navigate(['/teamhead/' + id, actionInfo]);
  }

  goToJoined(filterId: number) {
    this.router.navigate(['/applications/'+ filterId]);
  }

  goToApplications(id: number, actionInfo: number) {
    this.router.navigate(['/applications/0/' + id, actionInfo]);
  }

  goToBlock(id: number, actionInfo: number) {
    this.router.navigate(['/block/' + id, actionInfo]);
  }

  goToSector(id: number, actionInfo: number) {
    this.router.navigate(['/sector/' + id, actionInfo]);
  }

  goToAwc(id: number, actionInfo: number) {
    this.router.navigate(['/awc/' + id, actionInfo]);
  }

  goToAwcPage() {
    this.router.navigate(['/awc/']);
  }

  goTobeneficaryTime(id: number){
    this.router.navigate(['/childdetails/' + id]);
  }

}


