import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class DashboardService {

    getEventRoute = '/api/newevents/';

    constructor(private dataService: DataService) {
    };

    // getDashboard(districtId, blockId, villageId, date, isleastblock, refresh: boolean) {
    //     return this.dataService.getData('/api/dashboardchart/'
    //         + districtId + '/' + blockId
    //         + '/' + villageId + '/' + date + '/' + isleastblock, refresh);
    // }
    getDashboard(data, refresh: boolean) {
        return this.dataService.getData('/api/dashboard/dashboardbarchart/' + data.districtId + '/' + data.blockId, refresh);
    }

    getJobs(refresh: boolean) {
        return this.dataService.getData('/api/job/jobByProjectId', refresh);
    }

    getApplications(refresh: boolean) {
        return this.dataService.getData('/api/jobseeker', refresh);
    }
    getJobcodes(refresh: boolean){
      return this.dataService.getData('/api/project/jobCode', refresh);  
    }

    getDashboardData(refresh: boolean, fromDate, toDate){
        let queryString = "?applyFrom="+fromDate+"&applyTo="+toDate;
        
        return this.dataService.getData('/api/dashboard/dashboardcounts'+queryString, refresh);  
    }

    getDashboardApplicationList(refresh: boolean, fromDate, toDate){
        let queryString = "?applyFrom="+fromDate+"&applyTo="+toDate;
        
        return this.dataService.getData('/api/dashboard/dashboardByDate'+queryString, refresh);  
    }

    getProjects(refresh: boolean) {
        return this.dataService.getData('/api/project', refresh);
    }

    getLookUp(id,refresh: boolean) {
        return this.dataService.getData('/api/lookup/'+ id,refresh);
    }

    getProjectsUnderTeam(refresh: boolean) {
        return this.dataService.getData('/api/projectUnderTeam', refresh);
    }

    getById(id: number, refresh: boolean) {
        return this.dataService.getData('/api/project/' + id, refresh);
    }

    getByprojectUnderTeamId(id: number, refresh: boolean) {
        return this.dataService.getData('/api/projectUnderTeam/' + id, refresh);
    }

    getActionsByRoleIdStatusId(jobStatusId: number, roleId: number, refresh: boolean) {
        return this.dataService.getData('/api/application/' + jobStatusId + '/' +roleId, refresh);
    }

    getByJobId(id: number, refresh: boolean) {
        return this.dataService.getData('/api/job/' + id, refresh);
    }

    getByApplcationId(id: number, refresh: boolean) {
        return this.dataService.getData('/api/jobseeker/' + id, refresh);
    }

    downloadResume(id: number, refresh: any) {
        return this.dataService.getData('/api/jobseeker/downloadresume/'+id, refresh);
    }

    downloadProof(id: number, refresh: any) {
        return this.dataService.getData('/api/jobseeker/downloadproof/'+id, refresh);
    }

    createProject(result: any) {
        return this.dataService.post('/api/project', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }
    createJob(result: any) {
        return this.dataService.post('/api/job', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    createprojectUnderTeam(result: any) {
        return this.dataService.post('/api/projectUnderTeam', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    updateApplication(result: any) {
        return this.dataService.post('/api/jobseeker/post', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    updateJobSeeker(result: any) {
        return this.dataService.put('/api/jobseeker', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    getDashBoardData(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/dashboard/dashboardchart/' + data.districtId + '/' + data.blockId + '/' + data.sectorId, refresh);
    }

    getMapData(refresh: boolean, districtId, blockId, sectorId, awcId, malNutritionType) {
        return this.dataService.getData('/api/dashboard/mapdata/' + districtId + '/' + blockId + '/' + sectorId + '/' + awcId + '/' + malNutritionType, refresh);
    }

    getMapGeoJsonData(refresh: boolean, districtId, blockId, sectorId, awcId, malNutritionType) {
        return this.dataService.getData('/api/dashboard/mapgeojsondata/' + districtId + '/' + blockId + '/' + sectorId + '/' + awcId + '/' + malNutritionType, refresh);
    }

    getAllDashboardData(data: any, refresh: boolean) {
        return this.dataService.getData('/api/dashboard/dashboarddata/' + data.districtId + '/' + data.blockId + '/' + data.sectorId + '/' + data.awcId + '/' + data.seqId, refresh);
    }

    getDashboardDrillData(data: any, refresh: boolean) {
        return this.dataService.getData('/api/dashboard/dashboardawc/' + data.districtId + '/' + data.blockId + '/' + data.sectorId + '/' + data.awcId + '/' + data.severityType, refresh);
    }

    getMapGridData(refresh: boolean, districtId, blockId, sectorId, severityType) {
        return this.dataService.getData('/api/dashboard/mapgriddata/' + districtId + '/' + blockId + '/' + sectorId + '/' + severityType, refresh);
    }

    getPlannedActualAdditionalDatas(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/dashboard/mitargetactual/' + data.districtId + '/' + data.blockId, refresh);
    }

    getMiAgegroup(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/dashboard/miagegroup/' + data.districtId + '/' + data.blockId, refresh);
    }

    getGauageChartData(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/dashboard/migaugedata/' + data.districtId + '/' + data.blockId, refresh);
    }

    getMiTreatmentChartData(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/report/mimediacaltreatment/' + data.districtId + '/' + data.blockId, refresh);
    }

    getmedicalIntervention(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/child/mi/' + data.beneficiaryId, refresh);
    }

    getRutfData(data: any, refresh: boolean) {
        // tslint:disable-next-line: max-line-length
        return this.dataService.getData('/api/beneficiary/beneficiaryrutfstatus/' + data.beneficiaryId, refresh);
    }

    getmiEvaluation(data: any, refresh: boolean) {
        return this.dataService.getData('/api/child/mi/evaluationdetail/' + data.beneficiaryId, refresh);
    }

    getmiEvaluationByInspection(data1: any, refresh: boolean) {
        return this.dataService.getData('/api/child/mi/evaluationinspection/' + data1.inspectionId, refresh);
    }

    getmedicalInterventionList(data: any, refresh: boolean) {
        return this.dataService.getData('/api/child/awc/' + data.awcId, refresh);
    }

    getAliveStatus(refresh: boolean) {
        return this.dataService.getData('/api/lookup/alivestatustype', refresh);
    }

    getDeathStatus(refresh: boolean) {
        return this.dataService.getData('/api/lookup/deceasedcausetype', refresh);
    }

    getbeneficiarystatushistory(data: any, refresh: boolean) {
        return this.dataService.getData('/api/beneficiarystatushistory/' + data.beneficiaryId, refresh);
    }

    // getBeneficiaryNote(data: any, refresh: boolean) {
    //     return this.dataService.getData('/api/beneficiarynote/' + data.beneficiaryNoteId, refresh);
    // }

    getLastEntrydateofchild(data: any, refresh: boolean) {
        return this.dataService.getData('/api/beneficiarystatushistory/lastinspection/' + data.beneficiaryId, refresh);
    }

    beneficiaryStatusSave(result: any) {
        return this.dataService.post('/api/beneficiarystatushistory', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    beneficiaryNoteSave(result: any) {
        return this.dataService.post('/api/beneficiarynote', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    getPicmeDetails(rchId: any, refresh: boolean) {
        return this.dataService.getData('/api/picme/detail/' + rchId, refresh);
    }

    getSequenceLookUp(refresh: boolean, year: any) {
        return this.dataService.getData('/api/lookup/inspectionsequence/' + year, refresh);
    }

    getYearLookUp(refresh: boolean) {
        return this.dataService.getData('/api/lookup/year', refresh);
    }

    getRutfDashboardData(data: any, refresh: boolean) {
        return this.dataService.getData('/api/beneficiary/rutfchart/' + data.seqId + '/' + data.districtId + '/' + data.blockId + '/' + data.sectorId + '/' + data.awcId, refresh);
    }

    getEcpDashboardData(data: any, refresh: boolean) {
        return this.dataService.getData('/api/ec/ecpinspectionchart/' + data.seqId + '/' + data.districtId + '/' + data.blockId + '/' + data.sectorId + '/' + data.awcId, refresh);
    }

}
