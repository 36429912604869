import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/services/alert.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { UserSessionService } from 'src/app/services/usersession.service';
import swal from 'sweetalert2';
import { ExcelService } from 'src/app/services/excel.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'app-landingribbion',
  templateUrl: './landingribbion.component.html',
  styleUrls: ['./landingribbion.component.scss']
})
export class LandingribbionComponent implements OnInit {


  showRibben:boolean=true;

  constructor(
    public navigationService: NavigationService,
    private userSessionService: UserSessionService,
    private excelService: ExcelService,
    private translate: TranslateService,
    private userService: UserService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private router: Router) {
   
  }

  ngOnInit() {
    this.showRibben = true;
  }

   goToDashBoard(){
    this.showRibben=false;
    setTimeout(() => {
    this.router.navigateByUrl('/minewdashboard')
    }, 5000);
  }


}
